define("discourse/plugins/custom-changes/discourse/initializers/extend-home-logo", ["exports", "virtual-dom", "discourse-common/lib/icon-library", "discourse/models/session", "discourse/lib/plugin-api"], function (_exports, _virtualDom, _iconLibrary, _session, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "extend-for-home-logo",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.1", api => extendHomeLogo(api));
    }
  };
  function extendHomeLogo(api) {
    api.reopenWidget("home-logo", {
      logo() {
        const {
            siteSettings
          } = this,
          mobileView = this.site.mobileView;
        const darkModeOptions = _session.default.currentProp("darkModeAvailable") ? {
          dark: true
        } : {};
        const mobileLogoUrl = this.mobileLogoUrl(),
          mobileLogoUrlDark = this.mobileLogoUrl(darkModeOptions);
        const showMobileLogo = mobileView && mobileLogoUrl.length > 0;
        const logoUrl = this.logoUrl(),
          logoUrlDark = this.logoUrl(darkModeOptions);
        const title = siteSettings.title;
        if (this.attrs.minimized) {
          const logoSmallUrl = this.smallLogoUrl(),
            logoSmallUrlDark = this.smallLogoUrl(darkModeOptions);
          if (logoSmallUrl.length) {
            return this.logoElement("logo-small", logoSmallUrl, title, logoSmallUrlDark);
          } else {
            return (0, _iconLibrary.iconNode)("home");
          }
        } else if (showMobileLogo) {
          return this.logoElement("logo-mobile", mobileLogoUrl, title, mobileLogoUrlDark);
        } else if (logoUrl.length) {
          return this.logoElement("logo-big", logoUrl, title, logoUrlDark);
        } else {
          return (0, _virtualDom.h)("strong#site-text-logo.text-logo", {
            key: "logo-text"
          }, title);
        }
      }
    });
  }
});